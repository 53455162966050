import { Button } from "@3beehivetech/components";
import * as React from "react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import useNavigate from "../../hooks/useNavigate";
import {
  SpecialOfferContainer,
  SpecialOfferCta,
  SpecialOfferImg,
  SpecialOfferImgContainer,
} from "./styles";

const SpecialOffer = ({
  mobile,
  desktop,
  alt,
  linkTo,
  variant = "orange",
  label,
}) => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate(linkTo);

  return (
    <SpecialOfferContainer>
      <SpecialOfferImgContainer>
        {isMobile ? (
          <SpecialOfferImg
            role="button"
            src={mobile}
            alt={alt}
            width={314}
            height={370}
          />
        ) : (
          <SpecialOfferImg
            role="button"
            src={desktop}
            alt={alt}
          />
        )}
        {linkTo && (
          <SpecialOfferCta isMobile={isMobile}>
            <div style={{ padding: 10 }}>
              <Button variant={variant} onClick={navigate}>
                {label}
              </Button>
            </div>
          </SpecialOfferCta>
        )}
      </SpecialOfferImgContainer>
    </SpecialOfferContainer>
  );
};

export default SpecialOffer;
