import styled from "styled-components";

export const SpecialOfferImg = styled.img`
    max-width: 100%;

`;

export const SpecialOfferImgContainer = styled.div`
    position: relative;
    max-width: 78rem;
    display: inline-block;
`;

export const SpecialOfferContainer = styled.div`
    max-width: 78rem; 
    margin: auto;
    text-align: center;
    padding: 10rem 1.25rem 10rem 1.5625rem;
`;

export const SpecialOfferCta = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: ${({ isMobile }) => (isMobile ? "center" : "right")};
    padding: 10px;
`;
